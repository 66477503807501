<template>
    <div class="page-flag-1">
        <headBar
            :title="$store.state.flagJson.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
            left-arrow
            @click-left="newAppBack"
        />
        <div class="pd">
            <img
                class="banner"
                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png"
                alt=""
            />
            <div class="check-content">
                <h2 class="title f36">您的性别和生日是？</h2>
                <p class="f22 mt-8 grey">提交后不可修改，请认真填写哦~</p>
            </div>
            <div class="pub_avg sex-box">
                <div
                    class="sex"
                    :class="{ active: acindex == 1 }"
                    @click="setSexIndex(1)"
                >
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/men.png"
                    />
                </div>
                <div
                    class="sex"
                    :class="{ active: acindex == 0 }"
                    @click="setSexIndex(0)"
                >
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/women.png"
                    />
                </div>
            </div>
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                :show-toolbar="false"
                :visible-item-count="3"
                :item-height="52"
                :formatter="formatter"
                class="mydate"
            />
        </div>
        <div class="button-box fixed">
            <div class="cl-button f32 fw5 row-center-center" @click="goNext">
                下一步（ 1/7 ）
            </div>
        </div>
    </div>
</template>

<script>
import { closeWindow, initBack } from '@/lib/appMethod';
import { getList } from './index';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import {getFormatDate, getParam} from '@/lib/utils';
export default {
    mixins: [userMixin],
    components: {
        headBar,
    },
    data() {
        return {
            hasWriting: false, // 是否填写健康资料
            minDate: new Date(1960, 0, 1),
            maxDate: new Date(2013, 0, 1),
            currentDate: new Date(1970, 0, 1),
            acindex: -1,
        };
    },
    methods: {
        newAppBack() {
            closeWindow()
        },
        goNext() {
            if(this.acindex<0){
                this.$toast('请选择性别');
                return;
            }
            this.$store.commit('setFlagJson',{
                birthday:getFormatDate(this.currentDate,'yyyy-MM-dd'),
                sex:[this.acindex],
            })
            if (getParam().userNewcomerPowerRegularId) {
                sessionStorage.setItem('userNewcomerPowerRegularId', getParam().userNewcomerPowerRegularId) // 来自于新人权益h5的 userNewcomerPowerRegularId')
            }

            this.$router.push('page-2');
            this.countPoint('43','311','1473')
        },
        formatter(type, val) {
            if (type === 'year') {
                return ~~val;
            }
            if (type === 'month') {
                return ~~val;
            }
            if (type === 'day') {
                return ~~val;
            }
            return val;
        },
        setSexIndex(index){
            if(this.setSex){
                this.acindex=index;
            }else{
                this.$toast('您已填写过性别，不能再修改了哦~');
            }
        }
    },
    async created() {
        initBack()
        await this.$getAllInfo();
        // 性别
        getList(this.userId, this.token, 'sex').then(obj=>{console.log(obj)
            if(obj&&obj.codes.length>0){
                this.setSex=false;
                this.acindex=~~obj.codes[0]
            }else{
                this.setSex=true;
            }
        })

        // 生日
        getList(this.userId, this.token, 'birthday').then(value=>{console.log(value)
            if(value){
                this.currentDate=new Date(value.replace(/-/g,'/'));
            }
        })
        this.countPoint('43','311','1472')
    },
};
</script>
<style lang="less" scoped>
@import url(./index.less);
.page-flag-1 {
    background-color: #f9fafb;
    min-height: 100vh;
    .banner {
        margin-bottom: 32px;
    }
    .title {
        line-height: 50px;
    }
    .sex-box {
        margin: 50px 0 26px;
        .sex {
            width: 336px;
            height: 496px;
            border-radius: 16px;
            background: #f5f5f5;
            img {
                width: 100%;
                height: 100%;
            }
            &.active {
                border: 1px solid #232831;
            }
        }
    }
    /deep/ .mydate {
        background: #f9fafb;
        .van-picker-column__item {
            color: #6c727a;
            font-size: 28px;
        }
        .van-picker__mask {
            display: none;
        }
        .van-picker-column__item--selected {
            color: #3c454e;
            font-size: 42px;
            font-weight: 600;
        }
        .van-picker-column {
            position: relative;
            z-index: 1;
        }
        .van-picker__frame {
            background: #eee;
            left: 0;
            right: 0;
            z-index: 0;
            border-radius: 12px;
        }
    }
}
</style>
